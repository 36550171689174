@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../../style/Colors.scss";
@import "./QiFormComponent.scss";
@import "./QiButton.scss";
@import "./QiCheckBox.scss";
@import "./QiChipSelect.scss";
@import "./QiInput.scss";
@import "./QiModal.scss";
@import "./QiPagination.scss";
@import "./QiSelect.scss";
@import "./QiSpinnery.scss";
@import "./QiSwitch.scss";
@import "./QiListView.scss";
@import "./QiCustomSelect.scss";
@import "./QiDropdown.scss";
@import "./QiExpand.scss";
@import "./QiAccordion.scss";
@import "./QiPhotoUploader.scss";
@import "./QiMultiSelectDropdown.scss";
@import "./QITracksListCard.scss";
@import "./QiFlashScreen.scss";
@import "./QISlideSelect.scss";
@import "./QiTableCustom.scss";
@import "./QiTable.scss";
@import "./QICalendar.scss";
@import "./QIMonthYearRangePicker.scss";