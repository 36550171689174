.qi-month-year-range-picker {
    margin-bottom: 1.5rem;

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;

        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__date-section {
        background-color: #f9fafb;
        border-radius: 0.5rem;
        padding: 1rem;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #e5e7eb;
        transition: all 0.2s;

        &:hover {
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }
    }

    &__date-label {
        display: block;
        font-size: 0.875rem;
        font-weight: 500;
        color: #374151;
        margin-bottom: 0.75rem;
        display: flex;
        align-items: center;
    }

    &__icon {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
        color: #3b82f6;
    }

    &__fields {
        display: flex;
        gap: 0.75rem;
    }

    &__field {
        width: 50%;
    }

    &__field-label {
        display: block;
        font-size: 0.75rem;
        color: #6b7280;
        margin-bottom: 0.25rem;
        font-weight: 500;
    }

    &__select {
        width: 100%;
        border: 1px solid #d1d5db;
        border-radius: 0.375rem;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        background-color: white;
        transition: all 0.2s;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
            border-color: #60a5fa;
        }
    }
}