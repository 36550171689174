.time-picker-popper {
    z-index: 9999 !important;
}

.react-datepicker__input-time-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e7eb;
    padding-top: 0.75rem;

    .react-datepicker-time__caption {
        margin-right: 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        color: #4b5563;
    }

    .react-datepicker-time__input-container {
        display: flex;
        align-items: center;
    }

    .react-datepicker-time__input {
        border: 1px solid #d1d5db;
        border-radius: 0.25rem;
        padding: 0.1rem;
        margin-left: 0 !important;
        font-size: 0.875rem;

        &:focus {
            outline: none;
            ring: 2px solid #3b82f6;
            border-color: #3b82f6;
        }
    }

    input {
        width: 6rem;
        text-align: center;
        letter-spacing: 0.025em;
    }
}

.react-datepicker__month-container {
    padding-bottom: 0.5rem;
}

.example-custom-input {
    letter-spacing: 0.025em;
}